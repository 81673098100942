import { mediaPhoneOnly } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";

import Anchor from "../Anchor";
import Icon from "../Icon";
import type { IconColor, IconSource } from "../Icon/types";
import Text from "../Text";

interface ButtonLinkProps {
  backgroundColor: string;
  border?: string;
  children: ReactNode;
  className?: string;
  color: string;
  hoverColor: string;
  href: string;
  icon?: IconSource | null;
  iconColor?: IconColor | null;
  target?: string;
}

const ButtonLink = ({
  color,
  border = "none",
  backgroundColor,
  href,
  hoverColor,
  icon,
  iconColor,
  children,
  target = "_self",
  className,
}: ButtonLinkProps) => {
  return (
    <Anchor
      className={className}
      css={css`
        align-items: center;
        background-color: ${backgroundColor};
        border-radius: 6px;
        border: ${border};
        box-sizing: border-box;
        color: ${color};
        display: flex;
        gap: 8px;
        height: 40px;
        justify-content: center;
        padding: 16px;
        min-width: 160px;

        :hover {
          background-color: ${hoverColor};
          color: ${color};
        }

        @media (max-width: ${mediaPhoneOnly}) {
          width: 100%;
        }
      `}
      href={href}
      target={target}
    >
      {icon ? (
        <Icon color={iconColor || "inherit"} size={"16px"} source={icon} />
      ) : null}
      <Text
        as={"span"}
        color={"inherit"}
        css={css`
          white-space: nowrap;
        `}
        size={"small"}
        weight={"medium"}
      >
        {children}
      </Text>
    </Anchor>
  );
};

export default ButtonLink;
