import type { GetFooterQuery } from "@10x/types/__generated__/graphql-types";
import {
  borderStandard,
  mediaPhoneOnly,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Branding from "../../Branding";
import Flex from "../../Flex";
import Text from "../../Text";
import Form from "./Form";
import FooterItem from "./Item";
import LinkSection from "./LinkSection";
import SocialLinkSection from "./SocialLinkSection";
import type {
  FooterItem as FooterItemInterface,
  LinkSection as LinkSectionInterface,
} from "./types";

interface Props {
  className?: string;
  footerItems: FooterItemInterface[];
  form: GetFooterQuery["form"];
  linkSections: LinkSectionInterface[];
  socialLinks: LinkSectionInterface;
}

const Footer: FunctionComponent<Props> = ({
  className,
  linkSections,
  footerItems,
  form,
  socialLinks,
}) => {
  return (
    <footer className={className}>
      <div
        css={css`
          border-bottom: ${borderStandard};
          display: flex;
          justify-content: space-between;
          @media (max-width: ${mediaTabletLandscape}) {
            flex-direction: column;
            gap: 24px;
          }

          @media (max-width: ${mediaPhoneOnly}) {
            border-bottom: none;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            gap: 16px;
            max-width: 620px;
            width: 52%;

            @media (max-width: ${mediaTabletLandscape}) {
              width: 100%;
            }

            @media (max-width: ${mediaPhoneOnly}) {
              flex-direction: column;
              gap: 24px;
            }
          `}
        >
          {linkSections.map((linkSection) => {
            return <LinkSection key={linkSection.label} {...linkSection} />;
          })}
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            width: 37%;

            > div:first-of-type {
              padding: 0 0 1.5rem 0;
            }

            > *:not(:last-of-type) {
              border-bottom: ${borderStandard};
            }

            @media (max-width: ${mediaTabletLandscape}) {
              width: 100%;
            }

            @media (max-width: ${mediaPhoneOnly}) {
              > :last-of-type {
                border-bottom: ${borderStandard};
              }
            }
          `}
        >
          {footerItems.map((footerItem) => {
            return <FooterItem key={footerItem.title} {...footerItem} />;
          })}
          {form && <Form form={form} />}
          {socialLinks && <SocialLinkSection {...socialLinks} />}
          <Flex
            css={css`
              padding: 1.5rem 0;
            `}
            flexDirection={"column"}
            gap={"1rem"}
          >
            <Branding mode={"light"} />
            <Anchor className={"cky-banner-element"} href={"#"}>
              <Text
                as={"span"}
                color={"steelDarker"}
                css={css`
                  padding: 1.5rem 0;
                `}
                size={"xsmall"}
              >
                {"Manage Cookie Preferences"}
              </Text>
            </Anchor>
          </Flex>
        </div>
      </div>
      <div
        css={css`
          padding: 1.5rem 0;
        `}
      >
        <Text as={"div"} color={"steelDarker"} size={"xsmall"}>
          {`© ${new Date().getFullYear()} 10x Genomics. All Rights Reserved.`}
        </Text>
      </div>
    </footer>
  );
};

export default Footer;
