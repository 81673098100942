import { colorBlueDark, fontFamilyBase } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Text from "../../Text";
import type { LinkSection as Props } from "./types";

const LinkSection: FunctionComponent<Props> = ({ label, links }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        font-family: ${fontFamilyBase};
        gap: 1rem;
      `}
    >
      {label && (
        <Text as={"h3"} size={"xsmall"} weight={"semibold"}>
          {label}
        </Text>
      )}
      <ul
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
          list-style: none;
          margin: 0;
          padding: 0;
        `}
      >
        {links.map(({ label: linkLabel, url, target }) => {
          return (
            <li key={url}>
              <Anchor
                color={"midgray"}
                hoverColor={colorBlueDark}
                href={url}
                target={target}
              >
                {linkLabel}
              </Anchor>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default LinkSection;
