import {
  mediaDesktop,
  mediaPhoneOnly,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Flex from "../../Flex";
import Icon from "../../Icon";
import Text from "../../Text";
import ResourceLink from "./ResourceLink";
import type { Resource } from "./types";

const Resources: Resource[] = [
  {
    description:
      "Find step-by-step user guides and protocols for your specific 10x Genomics product.",
    label: "Find user guides",
    title: "User Guides",
    url: "/support/user-guides",
    variant: "primary",
  },
  {
    description:
      "Find answers to common technical questions, from sample prep through data analysis.",
    label: "View Knowledge base Q&A",
    target: "_blank",
    title: "Knowledge base Q&A",
    url: "https://kb.10xgenomics.com",
    variant: "secondary",
  },
  {
    description:
      "Search for technical documentation, datasets, videos, and more.",
    label: (
      <Flex alignItems={"center"} gap={".5rem"}>
        <Icon color={"blue"} size={"14px"} source={"search"} />
        {"Search support content"}
      </Flex>
    ),
    title: "Search",
    url: "/support/search",
    variant: "secondary",
  },
];

const Content = () => {
  return (
    <div
      css={css`
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-bottom: 3.5rem;
        max-width: 1280px;
        padding: 0 40px;
        width: 100%;
        @media (max-width: ${mediaDesktop}) {
          padding: 0 40px;
        }

        @media (max-width: ${mediaPhoneOnly}) {
          margin-bottom: 0;
          padding: 0 1.5rem;
        }
      `}
    >
      <Text
        as={"h1"}
        color={"steelDarkest"}
        size={"xxlarge"}
        weight={"semibold"}
      >
        {"Welcome to 10x Genomics Support"}
      </Text>

      <div
        css={css`
          display: flex;
          width: 100%;
          justify-content: space-between;
          gap: 48px;
          @media (max-width: ${mediaTabletLandscape}) {
            padding-bottom: 2rem;
            flex-direction: column;
            row-gap: 1.5rem;
          }
        `}
      >
        {Resources.map((resource) => {
          return <ResourceLink key={resource.title} {...resource} />;
        })}
      </div>
    </div>
  );
};

export default Content;
