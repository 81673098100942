import { colorBlueDark, fontFamilyBase } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Icon from "../../Icon";
import Text from "../../Text";
import type { FooterItem as Props } from "./types";

const LinkAndDescription: FunctionComponent<
  Pick<Props, "description" | "link">
> = ({ link, description }) => {
  return (
    <div
      css={css`
        font-family: ${fontFamilyBase};
      `}
    >
      <Text
        as={"span"}
        color={"steelDarker"}
        css={css`
          margin-right: 0.25rem;
        `}
        size={"xsmall"}
        weight={"medium"}
      >
        {description}
      </Text>
      <Anchor href={link.url} target={link.target}>
        {link.label}
      </Anchor>
    </div>
  );
};

const Link: FunctionComponent<Pick<Props, "link">> = ({ link }) => {
  return (
    <Anchor
      color={"blue"}
      css={{ alignItems: "center", display: "flex" }}
      hoverColor={colorBlueDark}
      href={link.url}
      target={link.target}
    >
      <Text
        as={"span"}
        color={"inherit"}
        css={css`
          display: block;
          margin-right: 0.25rem;
        `}
        size={"xsmall"}
        weight={"medium"}
      >
        {link.label}
      </Text>
      <Icon size={"14px"} source={"external-link"} />
    </Anchor>
  );
};

const Item: FunctionComponent<Props> = ({ link, description, title }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1.5rem 0;
      `}
    >
      <Text as={"h3"} size={"xsmall"} weight={"semibold"}>
        {title}
      </Text>
      {description ? (
        <LinkAndDescription description={description} link={link} />
      ) : (
        <Link link={link} />
      )}
    </div>
  );
};

export default Item;
