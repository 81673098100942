import { colorBlueDark } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Icon from "../../Icon";
import type { IconSource } from "../../Icon/types";
import Text from "../../Text";
import type { LinkSection as Props } from "./types";

const icons: Record<string, [IconSource, string]> = {
  facebook: ["facebook", "Facebook"],
  linkedin: ["linkedin", "LinkedIn"],
  twitter: ["twitter", "Twitter"],
  youtube: ["youtube", "Youtube"],
};

const SocialLinkSection: FunctionComponent<Props> = ({ label, links }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1.5rem 0;
      `}
    >
      {label && (
        <Text as={"h3"} size={"xsmall"} weight={"semibold"}>
          {label}
        </Text>
      )}
      <ul
        css={css`
          display: flex;
          list-style: none;
          gap: 1rem;
          margin: 0;
          padding: 0;
        `}
      >
        {links.map(({ label: icon, url, target }) => {
          const [iconName, title] = icons[icon];
          return (
            <li key={icon}>
              <Anchor
                color={"steelDark"}
                hoverColor={colorBlueDark}
                href={url}
                target={target}
                title={title}
              >
                <Icon color={"inherit"} size={"21px"} source={iconName} />
              </Anchor>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SocialLinkSection;
