import { getNavigation } from "@10x/cms/resolvers";
import getByGraphQL from "@10x/cms/utils/get-by-graphql";
import type {
  GetSupportHomePageDataQuery,
  GetSupportHomePageDataQueryVariables,
  Navigation,
} from "@10x/types/__generated__/graphql-types";
import SupportHomePage from "@10x/ui/src/Support";
import {
  DiscontinuedProductSlug,
  InstrumentSlug,
  ProductSlug,
  SOFTWARE_SLUGS,
} from "@10x/ui/src/Support/constants";
import type { NextPage } from "next";

import query from "../../charlie-queries/GetSupportHomePageData.graphql";
import { stripUndefineds } from "../../utils";

const CHROMIUM_SLUGS = [
  ProductSlug.SingleCellGeneExpression,
  ProductSlug.SingleCellImmuneProfiling,
  ProductSlug.SingleCellGeneExpressionFlex,
  ProductSlug.SingleCellMultiomeATACPlusGeneExpression,
  ProductSlug.SingleCellATAC,
];

const CHROMIUM_INSTRUMENT_SLUGS = [
  InstrumentSlug.ChromiumXSeries,
  InstrumentSlug.ChromiumController,
  InstrumentSlug.ChromiumConnect,
];

const VISIUM_SLUGS = [
  ProductSlug.SpatialGeneExpressionFFPE,
  ProductSlug.SpatialGeneExpressionFreshFrozen,
  ProductSlug.CytAssistSpatialGeneExpression,
  ProductSlug.SpatialGeneAndProteinExpression,
  ProductSlug.SpatialGeneExpressionHD,
];

const XENIUM_SLUGS = [ProductSlug.InSituGeneExpression];

const VISIUM_INSTRUMENT_SLUGS = [InstrumentSlug.VisiumCytAssist];

const XENIUM_INSTRUMENT_SLUGS = [InstrumentSlug.XeniumAnalyzer];

const LINKED_READS_DISCONTINUED_SLUGS = [
  DiscontinuedProductSlug.DeNovoAssembly,
];

const CHROMIUM_DISCONTINUED_SLUGS = [
  DiscontinuedProductSlug.GenomeAndExome,
  DiscontinuedProductSlug.SingleCellCNV,
];

interface Props {
  instruments: {
    chromiumInstruments: GetSupportHomePageDataQuery["chromiumInstruments"];
    visiumInstruments: GetSupportHomePageDataQuery["visiumInstruments"];
    xeniumInstruments: GetSupportHomePageDataQuery["xeniumInstruments"];
  };
  navigation: Navigation;
  products: {
    chromiumDiscontinuedProducts: GetSupportHomePageDataQuery["chromiumDiscontinuedProducts"];
    chromiumProducts: GetSupportHomePageDataQuery["chromiumProducts"];
    linkedReadsProducts: GetSupportHomePageDataQuery["linkedReadsProducts"];
    visiumProducts: GetSupportHomePageDataQuery["visiumProducts"];
    xeniumProducts: GetSupportHomePageDataQuery["xeniumProducts"];
  };
  softwares: GetSupportHomePageDataQuery["softwares"];
}

const SupportHome: NextPage<Props> = ({
  instruments,
  navigation,
  products,
  softwares,
}) => {
  return (
    <SupportHomePage
      instruments={instruments}
      navigation={navigation}
      products={products}
      softwares={softwares}
    />
  );
};

export const getStaticProps = async () => {
  const {
    chromiumInstruments,
    chromiumProducts,
    linkedReadsProducts,
    chromiumDiscontinuedProducts,
    softwares,
    visiumInstruments,
    xeniumInstruments,
    visiumProducts,
    xeniumProducts,
  } = await getByGraphQL<
    GetSupportHomePageDataQuery,
    GetSupportHomePageDataQueryVariables
  >({
    args: {
      chromiumDiscontinuedSlugs: CHROMIUM_DISCONTINUED_SLUGS,
      chromiumInstrumentSlugs: CHROMIUM_INSTRUMENT_SLUGS,
      chromiumSlugs: CHROMIUM_SLUGS,
      linkedReadsSlugs: LINKED_READS_DISCONTINUED_SLUGS,
      softwareSlugs: SOFTWARE_SLUGS,
      visiumInstrumentSlugs: VISIUM_INSTRUMENT_SLUGS,
      visiumSlugs: VISIUM_SLUGS,
      xeniumInstrumentSlugs: XENIUM_INSTRUMENT_SLUGS,
      xeniumSlugs: XENIUM_SLUGS,
    },
    locale: "en",
    query,
  });

  const products = {
    chromiumDiscontinuedProducts,
    chromiumProducts,
    linkedReadsProducts,
    visiumProducts,
    xeniumProducts,
  };

  const instruments = {
    chromiumInstruments,
    visiumInstruments,
    xeniumInstruments,
  };

  const navigation = await getNavigation("site-nav", "en");

  return {
    props: stripUndefineds({
      instruments,
      navigation,
      products,
      softwares,
    }),
  };
};

export default SupportHome;
