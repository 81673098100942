import type { Form_FullFragment } from "@10x/types/__generated__/graphql-types";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import SubscribeForm, { Mode } from "../../Blocks/Footer/SubscribeForm";
import Text from "../../Text";

interface Props {
  form: Form_FullFragment;
}

const SUBSCRIBE_URL = "https://pages.10xgenomics.com/subscription.html";

const Form: FunctionComponent<Props> = ({ form }) => {
  const [formFields] = form?.steps || [];

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1.5rem 0;
      `}
    >
      {formFields.title && (
        <Text
          as={"h1"}
          color={"steelDarkest"}
          size={"xsmall"}
          weight={"semibold"}
        >
          {formFields.title}
        </Text>
      )}
      {form && (
        <SubscribeForm
          data={form}
          mode={Mode.light}
          subscribeUrl={SUBSCRIBE_URL}
        />
      )}
    </div>
  );
};

export default Form;
