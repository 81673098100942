import {
  colorBlueDark,
  colorBlueLighter,
  colorBlueMedium,
  colorSteelLighter,
  mediaPhoneOnly,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Flex from "../../Flex";
import Text from "../../Text";
import ButtonLink from "../ButtonLink";
import type { Resource } from "./types";

const ResourceLink = ({
  description,
  label,
  title,
  url,
  target = "_self",
  variant,
}: Resource) => {
  const buttonStyles = {
    primary: {
      backgroundColor: colorBlueMedium,
      border: "none",
      color: "white",
      hoverColor: colorBlueDark,
    },
    secondary: {
      backgroundColor: "white",
      border: `1px solid ${colorSteelLighter}`,
      color: colorBlueMedium,
      hoverColor: colorBlueLighter,
    },
  };
  return (
    <Flex
      css={css`
        flex-direction: column;
        gap: 1rem;
      `}
    >
      <Flex
        css={css`
          flex-direction: column;
          gap: 0.25rem;
        `}
      >
        <Text
          as={"h2"}
          color={"steelDarkest"}
          size={"medium"}
          weight={"semibold"}
        >
          {title}
        </Text>
        <Text as={"p"} color={"steelDarker"} size={"small"} weight={"regular"}>
          {description}
        </Text>
      </Flex>

      <ButtonLink
        {...buttonStyles[variant]}
        css={css`
          height: 40px;
          width: 280px;

          @media (max-width: ${mediaPhoneOnly}) {
            width: 100%;
          }
        `}
        href={url}
        target={target}
      >
        <Text as={"span"} color={"inherit"} size={"small"} weight={"medium"}>
          {label}
        </Text>
      </ButtonLink>
    </Flex>
  );
};

export default ResourceLink;
