import { useAccordion } from "@10x/hooks";
import type {
  GetSupportHomePageDataQuery,
  Navigation_FullFragment,
} from "@10x/types/__generated__/graphql-types";
import {
  borderRadiusMedium,
  colorBlueDark,
  colorBlueLightest,
  colorBlueMedium,
  colorProductSpatial,
  colorWhite,
  mediaPhoneOnly,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Head from "../Head";
import Icon from "../Icon";
import Main from "../Main";
import Text from "../Text";
import ThematicBreak from "../ThematicBreak";
import Footer from "./Footer";
import { FOOTER_DATA } from "./Footer/constants";
import HomepageMasthead from "./HomepageMasthead";
import Content from "./HomepageMasthead/Content";
import SoftwareSelect from "./SoftwareSelect";
import InstrumentSelect from "./UserGuides/InstrumentSelect";
import InstrumentSelectCategory from "./UserGuides/InstrumentSelectCategory";
import ProductSelect from "./UserGuides/ProductSelect";
import ProductSelectCategory from "./UserGuides/ProductSelectCategory";
import type { InstrumentCategory, ProductCategory } from "./UserGuides/types";
import { getInstrumentsWithLink, getProductsWithLink } from "./utils";
import WidthWrapper from "./WidthWrapper";

interface Props {
  instruments: {
    chromiumInstruments: GetSupportHomePageDataQuery["chromiumInstruments"];
    visiumInstruments: GetSupportHomePageDataQuery["visiumInstruments"];
    xeniumInstruments: GetSupportHomePageDataQuery["xeniumInstruments"];
  };
  navigation: Navigation_FullFragment;
  products: {
    chromiumDiscontinuedProducts: GetSupportHomePageDataQuery["chromiumDiscontinuedProducts"];
    chromiumProducts: GetSupportHomePageDataQuery["chromiumProducts"];
    linkedReadsProducts: GetSupportHomePageDataQuery["linkedReadsProducts"];
    visiumProducts: GetSupportHomePageDataQuery["visiumProducts"];
    xeniumProducts: GetSupportHomePageDataQuery["xeniumProducts"];
  };
  softwares: GetSupportHomePageDataQuery["softwares"];
}

const SupportHomePage: FunctionComponent<Props> = ({
  instruments,
  navigation,
  products,
  softwares,
}) => {
  const { chromiumInstruments, visiumInstruments, xeniumInstruments } =
    instruments;
  const {
    chromiumProducts,
    chromiumDiscontinuedProducts,
    visiumProducts,
    xeniumProducts,
    linkedReadsProducts,
  } = products;

  const productCategories: ProductCategory[] = [
    {
      color: colorBlueMedium,
      products: getProductsWithLink(chromiumProducts),
      title: "Chromium",
    },
    {
      color: colorProductSpatial,
      products: getProductsWithLink(visiumProducts),
      title: "Visium",
    },
    {
      color: "#A842B3",
      products: getProductsWithLink(xeniumProducts),
      title: "Xenium",
    },
  ];

  const discontinuedProductCategories: ProductCategory[] = [
    {
      color: colorBlueMedium,
      products: getProductsWithLink(chromiumDiscontinuedProducts),
      title: "Chromium (discontinued)",
    },
    {
      color: "#00B1E4",
      products: getProductsWithLink(linkedReadsProducts),
      title: "Linked-reads (discontinued)",
    },
  ];

  const instrumentCategories: InstrumentCategory[] = [
    {
      color: colorBlueMedium,
      instruments: getInstrumentsWithLink(chromiumInstruments),
      title: "Chromium",
    },
    {
      color: colorProductSpatial,
      instruments: getInstrumentsWithLink(visiumInstruments),
      title: "Visium",
    },
    {
      color: "#A842B3",
      instruments: getInstrumentsWithLink(xeniumInstruments),
      title: "Xenium",
    },
  ];

  const content = discontinuedProductCategories.map(
    ({ color, products, title }: ProductCategory) => {
      return (
        <ProductSelectCategory
          color={color}
          css={css`
            :last-of-type {
              margin-bottom: 2rem;
            }
          `}
          key={title}
          products={products}
          title={title}
        />
      );
    },
  );

  const { active, children, contentRef, height, toggleAccordion } =
    useAccordion({ activeInitial: false, children: content });

  const viewMoreCopy = active
    ? "View less"
    : "View more products (including discontinued)";

  return (
    <>
      <Head
        description={"Support site"}
        ogImage={
          "https://cdn.10xgenomics.com/image/upload/v1654551191/social-share-images/social-support.png"
        }
        title={"Support"}
      />
      <HomepageMasthead navigation={navigation}>
        <Content />
      </HomepageMasthead>
      <Main
        background={colorWhite}
        css={css`
          min-height: auto;
          padding-top: 3rem;
        `}
      >
        <WidthWrapper width={"1200px"}>
          <div
            css={css`
              align-items: center;
              background: ${colorBlueLightest};
              border-radius: ${borderRadiusMedium};
              column-gap: 1.25rem;
              display: flex;
              min-height: auto;
              padding: 12px 24px;
              margin-bottom: 3rem;
            `}
          >
            <Icon
              color={"midgray"}
              css={css`
                flex-shrink: 0;
              `}
              size={"23px"}
              source={"megaphone"}
              yPos={"3px"}
            />
            <div
              css={css`
                align-items: center;
                column-gap: 1.25rem;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
              `}
            >
              <Text
                as={"span"}
                css={css`
                  @media (max-width: ${mediaPhoneOnly}) {
                    margin-bottom: 0.5rem;
                  }
                `}
                responsive={true}
                size={"medium"}
                weight={"medium"}
              >
                {"Workflow Update for Chromium Next GEM Assays"}
              </Text>
              <Anchor
                href={
                  "/support/single-cell-gene-expression/documentation/steps/library-prep/workflow-update-for-chromium-next-gem-assays"
                }
              >
                <Text
                  as={"span"}
                  color={"inherit"}
                  responsive={true}
                  size={"medium"}
                  weight={"medium"}
                >
                  {"Learn more"}
                </Text>
              </Anchor>
            </div>
          </div>
        </WidthWrapper>

        <ProductSelect>
          {productCategories.map(
            ({ color, products, title }: ProductCategory) => {
              return (
                <ProductSelectCategory
                  color={color}
                  key={title}
                  products={products}
                  title={title}
                />
              );
            },
          )}

          <div
            css={css`
              overflow: hidden;
              transition: max-height 0.2s cubic-bezier(0.46, 0.01, 0.92, 0.77);
              max-height: ${height};
            `}
            ref={contentRef}
          >
            {children}
          </div>

          <div
            css={css`
              display: flex;
              justify-content: center;
            `}
          >
            <button
              css={css`
                align-items: center;
                background: none;
                border: none;
                cursor: pointer;
                color: ${colorBlueMedium};
                display: flex;
                gap: 0.5rem;

                :hover {
                  color: ${colorBlueDark};
                }
              `}
              onClick={toggleAccordion}
            >
              <Text
                as={"div"}
                color={"inherit"}
                size={"small"}
                weight={"medium"}
              >
                {viewMoreCopy}
              </Text>
              {active ? (
                <Icon color={"inherit"} size={"8px"} source={"nav-up"} />
              ) : (
                <Icon color={"inherit"} size={"8px"} source={"nav-down"} />
              )}
            </button>
          </div>
        </ProductSelect>
        <InstrumentSelect>
          {instrumentCategories.map(
            ({ color, instruments, title }: InstrumentCategory) => {
              return (
                <InstrumentSelectCategory
                  color={color}
                  instruments={instruments}
                  key={title}
                  title={title}
                />
              );
            },
          )}
        </InstrumentSelect>
        <SoftwareSelect softwares={softwares} />
      </Main>
      <ThematicBreak
        css={css`
          margin: 0;
          padding: 0;
        `}
      />
      <div
        css={css`
          display: flex;
          justify-content: center;
          padding: 4rem 1.5rem;
        `}
      >
        <Footer
          {...FOOTER_DATA}
          css={css`
            width: 1200px;
          `}
        />
      </div>
    </>
  );
};

export default SupportHomePage;
